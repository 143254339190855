import { useNavigate, useSearchParams } from '@remix-run/react';
import {
  Fragment,
  type RefObject,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useEffectOnce } from 'react-use';
import { useSnapshot } from 'valtio';

import {
  type DtoBlock,
  type DtoGamePack,
  type DtoSharedAsset,
  EnumsGamePackChangeLevel,
} from '@lp-lib/api-service-client/public';
import { type Block } from '@lp-lib/game';

import { type UGCAnalytics } from '../../../analytics/ugc';
import { useInstance } from '../../../hooks/useInstance';
import { useOutsideClick } from '../../../hooks/useOutsideClick';
import { apiService } from '../../../services/api-service';
import {
  ConfirmCancelModalHeading,
  useAwaitFullScreenConfirmCancelModal,
} from '../../ConfirmCancelModalContext';
import { InfoIcon } from '../../icons/InfoIcon';
import { SettingIcon } from '../../icons/SettingIcon';
import { Loading } from '../../Loading';
import {
  BlockEditorStore,
  BlockEditorStoreProvider,
} from '../BlockEditorStore';
import { BlockEditor } from '../Blocks/Common/Editor';
import { ActivityBlockEditor } from './ActivityEditor';
import { CustomGamePackContainer } from './CustomGamePackLayout';
import { CustomGamePackHeader, CustomGamePackHeaderLeft } from './Nav';
import { type Activity, type EditorMode, type IndexedActivity } from './types';
import { UGCUtils, useUGCAnalytics } from './utils';

function ActivityList(props: {
  activities: IndexedActivity[];
  activeActivityId: string | null;
  onClick?: (activity: Activity) => void;
}) {
  const { activities, activeActivityId, onClick } = props;
  return (
    <div className='text-sms text-icon-gray mt-10 px-5'>
      <p>Activity List</p>
      <ol className='list-none list-inside flex flex-col mt-4'>
        {activities.map((activity) => (
          <li key={activity.block.id} className={`relative`}>
            <button
              type='button'
              className={`w-full text-left px-4 py-2.5 hover:bg-white hover:bg-opacity-15 
                rounded-xl ${
                  activeActivityId === activity.block.id
                    ? 'text-white font-bold'
                    : ''
                }`}
              onClick={() => onClick?.(activity)}
            >
              {activity.index + 1}. {activity.asset.primaryText}
            </button>
            {activeActivityId === activity.block.id && (
              <div
                className='absolute h-4/5 w-0.5 bg-red-002 rounded-sm 
              left-0 top-1/2 transform -translate-y-1/2'
              ></div>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
}

function EditorHeader(props: {
  activity: IndexedActivity;
  activitiesCount: number;
  onClickNextButton?: () => void;
}) {
  const { activity, activitiesCount, onClickNextButton } = props;
  const { asset } = activity;
  const [showHint, setShowHint] = useState(false);

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    tooltipRef,
    triggerRef,
    visible,
  } = usePopperTooltip({
    trigger: 'hover',
    placement: 'auto',
    visible: showHint,
  });

  useOutsideClick(tooltipRef, () => setShowHint(false), triggerRef);

  return (
    <header className='w-full flex items-center justify-between'>
      <div className='flex flex-col'>
        <div className='flex items-center gap-2'>
          <p className='text-white text-xl font-bold'>
            {asset.primaryText}{' '}
            {`(${activity.index + 1} of ${activitiesCount})`}
          </p>
          {asset.hint && (
            <button
              type='button'
              ref={setTriggerRef}
              onMouseEnter={() => setShowHint(true)}
              onMouseLeave={() => setShowHint(false)}
            >
              <InfoIcon className='w-3.5 h-3.5' color='#8B9294' />
            </button>
          )}
        </div>
        <div className='text-icon-gray text-sms font-medium'>
          {asset.secondaryText}
        </div>
      </div>
      <div>
        <button
          type='button'
          className='btn-primary w-33 h-10 flex items-center justify-center font-medium'
          onClick={onClickNextButton}
        >
          {activity.index + 1 < activitiesCount ? 'Next' : 'Finish'}
        </button>
      </div>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className:
              'w-75 bg-white rounded-lg px-4 py-3 flex flex-col gap-1.5',
          })}
        >
          <div className='text-black text-sms font-bold'>How it works</div>
          <div className='text-black text-sms'>{asset.hint}</div>
        </div>
      )}
    </header>
  );
}

function UnsupportedActivityBlockEditor(props: {
  setMode: (mode: EditorMode) => void;
}) {
  useEffectOnce(() => props.setMode('advanced'));
  return null;
}

function Editor(props: {
  packId: string;
  activity: IndexedActivity;
  activitiesCount: number;
  onClickNextButton: () => void;
  mode: EditorMode;
  setMode: (mode: EditorMode) => void;
  setSavingChanges: (saving: boolean) => void;
  analytics: UGCAnalytics;
  enableAI?: boolean;
  root?: RefObject<Element>;
}) {
  const { activity, mode } = props;
  const store = useInstance(() => new BlockEditorStore());

  useLayoutEffect(() => {
    store.setBlocks(activity.block);
  }, [store, activity.block]);

  const block = useSnapshot(store.state).blocks.find(
    (b) => b.id === activity.block.id
  ) as Block | undefined;

  return (
    <BlockEditorStoreProvider store={store}>
      <section className='w-full h-full flex flex-col gap-2 min-w-230'>
        <EditorHeader {...props} />
        <div className='w-full h-full flex flex-col overflow-y-auto scrollbar relative'>
          {mode === 'advanced' ? (
            <BlockEditor
              block={block ?? null}
              setSavingChanges={props.setSavingChanges}
              hideSharedFields
            />
          ) : (
            <Fragment>
              {block ? (
                <ActivityBlockEditor
                  packId={props.packId}
                  block={block}
                  asset={activity.asset}
                  setSavingChanges={props.setSavingChanges}
                  unsupported={() => (
                    <UnsupportedActivityBlockEditor setMode={props.setMode} />
                  )}
                  analytics={props.analytics}
                  enableAI={props.enableAI}
                  root={props.root}
                />
              ) : null}
            </Fragment>
          )}
        </div>
      </section>
    </BlockEditorStoreProvider>
  );
}

function RightPanel(props: {
  editorMode: EditorMode;
  onClickSwitchEditorMode?: () => void;
}) {
  return (
    <div className='w-full h-full flex items-end justify-end'>
      <button
        type='button'
        className='text-icon-gray text-sms mr-8 mb-4 hover:text-white flex items-center justify-center gap-2'
        onClick={props.onClickSwitchEditorMode}
      >
        <SettingIcon className='w-4 h-4 fill-current' />
        <p>{props.editorMode === 'standard' ? 'Advanced' : 'Standard'}</p>
      </button>
    </div>
  );
}

function HeaderSaveButton(props: {
  disabled?: 'processing' | 'error' | null;
  onClick?: () => void;
  savingChanges?: boolean;
}) {
  return (
    <button
      type='button'
      className='btn text-sms text-icon-gray hover:text-white 
      flex items-center justify-center gap-2 relative'
      onClick={props.onClick}
      disabled={!!props.disabled}
    >
      {props.disabled === 'processing' && (
        <Loading imgClassName='w-3.5 h-3.5' text='' />
      )}
      <p>Save & Exit</p>
    </button>
  );
}

export function CustomGamePackEditor(props: {
  pack: DtoGamePack;
  blocks: DtoBlock[];
  assets: DtoSharedAsset[];
  created?: boolean;
  enableAI?: boolean;
}) {
  const { pack, blocks, assets, created } = props;
  const nagivate = useNavigate();
  const activities = useMemo(() => {
    return UGCUtils.MakeActivities(pack, blocks, assets);
  }, [pack, blocks, assets]);
  const [searchParams, setSearchParams] = useSearchParams();
  const firstActivityId = activities.length > 0 ? activities[0].block.id : null;
  const activeActivityId = searchParams.get('activity-id') ?? firstActivityId;
  const activeActivity = activities.find(
    (a) => a.block.id === activeActivityId
  );
  const [editorMode, setEditorMode] = useState<EditorMode>('standard');
  const confirmCancel = useAwaitFullScreenConfirmCancelModal();
  const [name, setName] = useState(pack.name);
  const [savingChanges, setSavingChanges] = useState(false);
  const analytics = useUGCAnalytics();
  const ref = useRef<HTMLDivElement>(null);

  const onClickActivity = (activity: Activity) => {
    setEditorMode('standard');
    setSearchParams((prev) => {
      prev.set('activity-id', activity.block.id);
      return prev;
    });
  };

  const onClickSaveButton = () => {
    analytics.trackEditorSaveClicked({
      packId: pack.id,
      context: !!created ? 'create' : 'edit',
    });
    nagivate(`/custom-games?packId=${pack.id}`);
  };

  const onClickNextButton = () => {
    if (!activeActivity) return;
    const next = activities.find((a) => a.index > activeActivity.index);
    if (next) {
      analytics.trackEditorNextClicked({
        packId: pack.id,
        blockId: next.block.id,
        blockType: next.block.type,
      });
      onClickActivity(next);
    } else {
      analytics.trackEditorFinishClicked({ packId: pack.id });
      onClickSaveButton();
    }
  };

  const onClickSwitchEditorMode = async () => {
    if (!activeActivity) return;
    if (editorMode === 'standard') {
      const response = await confirmCancel({
        kind: 'confirm-cancel',
        prompt: (
          <div className='text-white flex-col items-center justify-center py-2 px-4'>
            <ConfirmCancelModalHeading>
              Adventurers Be Warned
            </ConfirmCancelModalHeading>
            <div className='text-sms text-center my-2'>
              You’re about to see how the sausage is made. These are advanced
              settings that are not well optimized for public consumption. Feel
              free to play around, but always play test, as you could alter
              something that breaks your game.
            </div>
          </div>
        ),
        confirmBtnVariant: 'delete',
        confirmBtnLabel: 'I’ll risk it',
        cancelBtnLabel: 'Nevermind',
        autoFocus: 'cancel',
      });

      if (response.result === 'canceled') return;
      analytics.trackEditorAdvancedClicked({
        blockId: activeActivity.block.id,
        blockType: activeActivity.block.type,
      });
    }
    setEditorMode(editorMode === 'standard' ? 'advanced' : 'standard');
  };

  const actionDisabled = savingChanges ? 'processing' : null;

  const onNameChange = async (val: string) => {
    setName(val);
    setSavingChanges(true);
    try {
      await apiService.gamePack.update(pack.id, {
        name: val,
        changeLevel: EnumsGamePackChangeLevel.GamePackChangeLevelMinor,
      });
      analytics.trackCustomGameNameChanged({
        packId: pack.id,
        packName: val,
      });
    } catch (error) {
      setName(pack.name);
    } finally {
      setSavingChanges(false);
    }
  };

  return (
    <Fragment>
      <CustomGamePackHeader
        left={
          <CustomGamePackHeaderLeft
            title={name}
            onClickBack={() => nagivate('..')}
            onTitleChange={onNameChange}
          />
        }
        right={
          <HeaderSaveButton
            disabled={actionDisabled}
            onClick={onClickSaveButton}
          />
        }
      />
      <CustomGamePackContainer
        left={
          <ActivityList
            activities={activities}
            activeActivityId={activeActivityId}
            onClick={(activity) => {
              onClickActivity(activity);
              analytics.trackEditorActivityClicked({
                blockId: activity.block.id,
                blockType: activity.block.type,
              });
            }}
          />
        }
        right={
          <RightPanel
            editorMode={editorMode}
            onClickSwitchEditorMode={onClickSwitchEditorMode}
          />
        }
      >
        {activeActivity ? (
          <Editor
            packId={pack.id}
            activity={activeActivity}
            activitiesCount={activities.length}
            onClickNextButton={onClickNextButton}
            mode={editorMode}
            setSavingChanges={setSavingChanges}
            setMode={setEditorMode}
            analytics={analytics}
            enableAI={props.enableAI}
            root={ref}
          />
        ) : (
          <div className='w-full h-full flex items-center justify-center text-white'>
            No activities available
          </div>
        )}
      </CustomGamePackContainer>
      <div ref={ref}></div>
    </Fragment>
  );
}

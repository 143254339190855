import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { useTitle } from 'react-use';

import {
  CustomGamePackEditor,
  CustomGamePackLayout,
} from '../components/Game/UGC';
import { getFeatureQueryParam } from '../hooks/useFeatureQueryParam';
import { apiService } from '../services/api-service';
import { makeTitle } from '../utils/common';
import { tokenWithRedirect } from '../utils/router';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });

  const resp = await tokenWithRedirect(
    () =>
      apiService.gamePack.getGamePackById(id, {
        blocks: true,
        brands: true,
        linkedSharedAssets: true,
      }),
    action.request.url
  );

  return { ...resp.data, created: url.searchParams.get('created') === 'true' };
}

export function Component() {
  const { gamePack, blocks, linkedSharedAssets, created } =
    useLoaderData<typeof clientLoader>();
  useTitle(makeTitle(`Customize ${gamePack.name}`));
  return (
    <CustomGamePackLayout>
      <CustomGamePackEditor
        pack={gamePack}
        blocks={blocks ?? []}
        assets={linkedSharedAssets ?? []}
        created={created}
        enableAI={getFeatureQueryParam('game-pack-ugc-ai')}
      />
    </CustomGamePackLayout>
  );
}
